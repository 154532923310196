
import { closeCircleOutline, caretUpOutline, caretDownOutline } from 'ionicons/icons';
import ud from "@/modules/cyanUserData";
import { territorios } from "@/modules/cyanRegions";

import { defineComponent } from "vue";
import {
  IonPage,
  IonButton,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  modalController
} from "@ionic/vue";

export default defineComponent({
  name: "ModalSelectorTipoIncidencia",
  components: {
    IonButton,
    IonPage,
  IonGrid,
  IonRow,
  IonCol,
    IonIcon
  },
  setup() {
    return {
      closeCircleOutline, caretUpOutline, caretDownOutline
    }
  },
  data() {
    return {
      epigrafeActual: ''
    };
  },
  computed: {

    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    tiposIncidencia(): any {
      return ud('tiposIncidencia', {'0':'Error'});
    },
    epigrafesIncidencias(): any {
      return ud('epigrafesIncidencias', {});
    },
    incidenciasIndexadas(): any {
      const r = {} as any;

      let epigrafeActual = '';

      for(const i in this.tiposIncidencia) {
        if (this.epigrafesIncidencias[i]) {
          epigrafeActual = this.epigrafesIncidencias[i];
          if (!r[epigrafeActual])
            r[epigrafeActual] = [];
        }
          r[epigrafeActual].push(i);
      }

      return r;


    }
  },
  methods: {
    cerrarModal: function(x?: any) { modalController.dismiss(x); },
    epigrafeChanger(e: any) {
      if (this.epigrafeActual == '') {
        this.epigrafeActual = e;
        return;
      }
      this.epigrafeActual = '';

      if (e != '') {
        const t = this as any;
        new Promise(function(resolve) { setTimeout(resolve,500);}).then(function() {t.epigrafeActual = e});
      }
    }
  },
});
