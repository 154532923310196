
import store from "@/modules/adaptedStorage";
import CyanPageWrapper from '@/components/CyanPageWrapper.vue';
import ModalVideo from '@/views/ModalVideo.vue';
import { defineComponent } from "vue";
import {
  IonPage,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  modalController,
  alertController,
} from "@ionic/vue";

import {
  attach,
  imagesOutline,
  filmOutline,
  videocamOutline,
  alertCircleOutline,
  checkmarkCircleOutline,
  banOutline,
  searchCircleOutline,
  mailUnreadOutline,
} from "ionicons/icons";

import ReportarIncidenciaStreamlined from "@/views/ReportarIncidenciaStreamlined.vue";
import { territorios } from "@/modules/cyanRegions";
import cyanRequest from "@/modules/cyanRequest";
import ud from "@/modules/cyanUserData";

import cyanGetPhoto from '@/modules/cyanGetPhoto'

export default defineComponent({
  name: "IncidenciasStreamlined",
  components: {
    IonPage,
    IonButton,
    IonList,
    IonIcon,
    IonItem,
    IonGrid,
    IonRow,
    IonCol,
    CyanPageWrapper
  },
  setup() {

    const iconosEstado = {
      '1': mailUnreadOutline,
      '2': searchCircleOutline,
      '3': banOutline,
      '4': checkmarkCircleOutline
    };

    const coloresEstado = {
      '1': 'secondary',
      '2': 'warning',
      '3': 'danger',
      '4': 'success'
    };

    return {
      attach,
      iconosEstado,
      coloresEstado,
      imagesOutline,
      filmOutline,
      videocamOutline,
      alertCircleOutline,
      checkmarkCircleOutline,
    };
  },
  data() {
    return {
      completo: false
    };
  },
  computed: {
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in territorios)) return "";
      return territorios[n].nombre + ", " + territorios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },

    incidencias(): any {
      return ud('incidencias',[]);
    },
    incidenciasAbiertas(): any {
      return this.incidencias.filter(function(x: any) { return x.estado_incidencia_id<3;});
    },
    numIncidenciasCerradas(): number {
      return this.incidencias.length - this.incidenciasAbiertas.length;
    },
    incidenciasSeleccionadas(): any {
      return this.completo ? this.incidencias : this.incidenciasAbiertas;
    },
    fotos(): any {
      const obj = {} as any;
      for(const i in this.incidencias) {
        const medios = [];
        for (const j in this.incidencias[i].medios) {
          const m = this.incidencias[i].medios[j];
          if (m.tipo_archivo.split('/')[0] == 'image')
            medios.push(m);
        }
        obj[this.incidencias[i].id] = medios;
      }
      return obj;
    },
    videos(): any {
      const obj = {} as any;
      for(const i in this.incidencias) {
        const medios = [];
        for (const j in this.incidencias[i].medios) {
          const m = this.incidencias[i].medios[j];
          if (m.tipo_archivo.split('/')[0] == 'video')
            medios.push(m);
        }
        obj[this.incidencias[i].id] = medios;
      }
      return obj;
    },

  },
  methods: {
    async nuevaIncidencia() {
      const modal = await modalController.create({
        component: ReportarIncidenciaStreamlined,
        cssClass: "fullscreen",
      });
      return modal.present();
    },

    async marcarResuelta(idIncidencia: any) {
      const confirmPromise = (): Promise<boolean> => {
        return new Promise((resolve) => {
          alertController
            .create({
              message:
                "¿Está seguro de que quiere mandar esta incidencia como resuelta?",
              buttons: [
                {
                  text: "Sí",
                  handler: () => resolve(true),
                },
                {
                  text: "No",
                  role: "cancel",
                  handler: () => resolve(false),
                },
              ],
            })
            .then((alert) => alert.present());
        });
      };

      if (await confirmPromise()) {
        cyanRequest("jrsl/closeIncidencia/" + idIncidencia, {
          isModal: true,
          isPost: true,
          needsAuth: true,
          retries: 3
        }).then(function (d) {
          if (d.ok) {
            store.commit("patchUserData", d);
          }
        });
      }
    },

    async enviarFoto(idIncidencia: any, video?: any) {

      const image = await cyanGetPhoto(video);

      if (image === false) {
        return;
      }

      cyanRequest("jrsl/fotoIncidencia/" + idIncidencia, {
        isPost: true,
        retries: 3,
        files: { fichero: image },
        isModal: "Subiendo " + (video ? 'video' : 'foto') + "...",
        needsAuth: true,
      }).then(function (d) {
        if (d.ok) {
          store.commit("patchUserData", d);
        }
      });
    },
    verFotos(incidencia: any) {
      const result = [];
      if (this.fotos[incidencia.id].length) {
        let counter = 1;
        for (const i of this.fotos[incidencia.id]) {
          result.push({
            src: i.url,
            title: "Imagen " + counter++ + " de " + this.fotos[incidencia.id].length,
          });
        }
      }

      if (result.length) {
        store.commit("setLightboxImages", result);
        store.commit("setLightboxVisible", true);
      }
    },
    verVideos(incidencia: any) {
      if (this.videos[incidencia.id].length == 0 ) return;

      modalController.create({
        component: ModalVideo,
        componentProps: {
          videos: this.videos[incidencia.id]
        }
      }).then(function(m) {
        m.present();
      });

    },
  },
});
